import isWebkit from '../utils/isWebkit';

/**
 * This is a workaround for a bug in WebKit which causes images with responsive sources to be blurry.
 * The fix has been merged and this code should be removed when it's released into Safari, possibly 16.2.
 * See https://frameworkmarketplace.atlassian.net/browse/FWM-2316?focusedCommentId=28850
 */
if (isWebkit(navigator)) {
  document.addEventListener('turbo:render', () => {
    document.querySelectorAll('img[srcset]').forEach((node) => {
      node.outerHTML = node.outerHTML;
    });
  });
}
