import Analytics from './analytics';
import Loader from './loader';
import Meta from './meta';

const Compliance = {
  load() {
    document.addEventListener('turbo:load', ({ detail }) => {
      const { url } = detail;
      Compliance.start(url);
    });
  },

  start(url = document.location.href) {
    const complianceEnabled = Meta.findByName('compliance-enabled');
    if (complianceEnabled !== 'true') {
      this.startAnalytics(url);
      return;
    }

    const policyId = Meta.findByName('policy-id');
    this.loadCookieConsent(policyId, url);
    this.loadPolicies(policyId);
  },

  loadCookieConsent(policyId, url) {
    const complianceRequired = Meta.findByName('compliance-required');

    if (complianceRequired !== 'true') {
      this.startAnalytics(url);
      return;
    }

    if (policyId && this.isConsentRead() && this.isMeasurementConsent()) {
      this.startAnalytics(url);
      return;
    }

    const language = Meta.findByName('language') || 'en';
    this.init({ policyId, language, url });
    Loader.loadScript('https://cdn.iubenda.com/cs/iubenda_cs.js', () =>
      this.onConsent(url),
    );
  },

  loadPolicies(policyId) {
    if (!policyId) return;

    Loader.loadScript('https://cdn.iubenda.com/iubenda.js');
  },

  init(options) {
    window._iub = window._iub || [];
    window._iub.csConfiguration = {
      consentOnContinuedBrowsing: false,
      consentOnScroll: false,
      cookiePolicyId: options.policyId,
      enableGdpr: true,
      floatingPreferencesButtonDisplay: 'bottom-right',
      gdprAppliesGlobally: false,
      lang: options.language,
      perPurposeConsent: true,
      siteId: 2095007,
      banner: {
        backgroundOverlay: true,
        position: 'float-bottom-center',
        textColor: '#333333',
        backgroundColor: '#ffffff',
        acceptButtonDisplay: true,
        acceptButtonColor: '#f45a27',
        acceptButtonCaptionColor: '#ffffff',
        customizeButtonDisplay: true,
        customizeButtonColor: '#f2f2f2',
        customizeButtonCaptionColor: '#333333',
        rejectButtonDisplay: true,
        rejectButtonColor: '#333333',
        rejectButtonCaptionColor: '#ffffff',
        listPurposes: true,
      },
      callback: {
        onConsentRead: () => this.onConsent(options.url),
      },
    };
  },

  onConsent(url) {
    const consent = window._iub?.cs?.consent;
    if (!consent?.purposes) return;

    const measurementConsent = consent.purposes[4];
    if (measurementConsent === true) {
      this.startAnalytics(url);
    }

    this.setMeasurementConsent(measurementConsent);
    this.setConsentRead(true);
  },

  startAnalytics(url) {
    Analytics.start();
    Analytics.page(null, { url });
    Analytics.identify();
  },

  setMeasurementConsent(consent) {
    try {
      sessionStorage.setItem('measurementConsent', consent);
    } catch (ignore) {}
  },

  getMeasurementConsent() {
    return sessionStorage.getItem('measurementConsent');
  },

  isMeasurementConsent() {
    try {
      return this.getMeasurementConsent() || false;
    } catch (ignore) {
      return false;
    }
  },

  setConsentRead(consent) {
    try {
      sessionStorage.setItem('complianceConsentRead', consent);
    } catch (ignore) {}
  },

  getConsentRead() {
    return sessionStorage.getItem('complianceConsentRead');
  },

  isConsentRead() {
    try {
      return this.getConsentRead() || false;
    } catch (ignore) {
      return false;
    }
  },
};

export default Compliance;
