import Loader from './loader';
import Meta from './meta';

const RemoteContent = {
  start() {
    const repo = Meta.findByName('remote-content-preview-repo');
    if (!repo) {
      return;
    }

    const url = `https://static.cdn.prismic.io/prismic.js?repo=${repo}&new=true`;
    Loader.loadScript(url);
  },
};

export default RemoteContent;
