import Compliance from './application/compliance';
import Monitoring from './application/monitoring';
import RemoteContent from './application/remote_content';
import './application/webkit_srcset_fix';
import './application/webkit_autoplay_video';
import * as ActionCable from '@rails/actioncable';

Monitoring.load();
Compliance.load();
RemoteContent.start();

window.Cable = ActionCable;
if (window.App == undefined) {
  window.App = {};
}
App.cable = ActionCable.createConsumer();
