import * as Sentry from '@sentry/browser';
import Meta from './meta';
import User from './user';

const Monitoring = {
  load() {
    const dsn = Meta.findByName('monitoring-key');
    if (!dsn) {
      return;
    }

    this.loadSentry(dsn);
  },

  loadSentry(dsn) {
    Sentry.init({
      allowUrls: [/frame\.work/],
      dsn,
      environment: Meta.findByName('release-stage'),
      ignoreErrors: this.getIgnoredErrors(),
      release: Meta.findByName('release-version'),
      sendDefaultPii: true,
    });

    const id = User.id();
    if (id) {
      Sentry.setUser({ id });
    }
  },

  getIgnoredErrors() {
    return [
      "Can't find variable: QK_middlewareReadModePageDetect",
      "Can't find variable: _AutofillCallbackHandler",
      "Can't find variable: article",
      "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
      "Can't find variable: setIOSParameters",
      "Can't find variable: webkit",
      "Cannot read properties of null (reading 'disconnect')",
      "Cannot read properties of undefined (reading 'scroller')",
      "Cannot read property 'getItem' of null",
      "Cannot read property 'scroller' of undefined",
      "Identifier '__toUserAgent' has already been declared",
      "SecurityError: Failed to execute 'pushState' on 'History':",
      "document.getElementsByTagName('video')[0].webkitExitFullScreen')",
      "null is not an object (evaluating 'e.domainName')",
      "null is not an object (evaluating 'element.value = token')",
      '.addClass is not a function',
      '.apply is not a function',
      '.children(...).addClass is not a function',
      '.children(...).remove is not a function',
      '.nextAll(...).eq is not a function',
      '.parents(...).eq is not a function',
      '.prevAll(...).eq is not a function',
      '.removeAttr is not a function',
      '.removeClass is not a function',
      'Event missing',
      'Internal error while estimating storage usage',
      'MtPopUpList is not defined',
      'Non-Error promise rejection captured with keys:',
      'Non-Error promise rejection captured with value:',
      'Permission denied to access property "nodeType"',
      'ResizeObserver loop',
      'SecurityError: Blocked a frame with origin "https://frame.work"',
      'The operation is insecure.',
      'Unknown player. Probably unloaded',
      '__ybro.readability.runDetection',
      'can\'t redefine non-configurable property "userAgent"',
      'ceCurrentVideo.currentTime',
      'is not allowed by the user agent',
      'jQuery is not defined',
      'pktAnnotationHighlighter',
      'rails-ujs has already been loaded!',
      'redeclaration of const __toUserAgent',
      'request was interrupted by a call to pause',
      't.classList.replace is not a function',
      'this.hostIndex.push is not a function',
      'this.slides.transition(...).find(...).transition is not a function',
      'toTopBtn is not defined',
      'window.bannerNight',
      'window.webkit.messageHandlers',
      'window.webkit.messageHandlers.selectedDebugHandler.postMessage',
      'window.webkit.messageHandlers.selectedText',
    ];
  },
};

export default Monitoring;
